<template>
  <b-icon class="fullscreen" :style="{ color: color || 'black' }" @click="$store.commit('game/TOGGLE_FULLSCREEN')" :icon="$store.state.game.fullscreen ? 'fullscreen-exit' : 'fullscreen'" /> 
</template>

<script>
export default {
  props: {
    color: String,
  }
}
</script>

<style scoped>
.fullscreen {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 3;
  cursor: pointer;
}
</style>