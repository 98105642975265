<template>
  <div class="d-flex flex-column min-vh-100 flex-grow-1 justify-content-center" :style="{ width: '100vw', backgroundColor: countdown === 0 ? fallbackCoverColor : backgroundColor }">
    <FullScreenButton :color="gameState === 'unveiled' ? textColor : 'black'"/>
    <div class="blurCover" v-if="countdown === 0">
      <img :src="coverUrl" class="blurImage" alt="" srcset="">
    </div>
    <img :src="hiddenCoverUrl" id="cover" crossorigin="anonymous" style="display: none " alt="" srcset="">

    <!-- loading tracks -->
    <div class="d-flex flex-row" style="width: 100vw;" v-if="loadingTracks">
      <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
        <h1 style="color: black; position: absolute; font-size: 800%">loading playlist...</h1>
      </div>
    </div>

    <!-- guessing state -->
    <div class="d-flex flex-row flex-grow-1" style="width: 100vw; z-index: 2" v-if="!loadingTracks"  @click="rand">
      <!-- cover & countdown -->
      <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center" v-if="gameState === 'guessing' || gameState === 'paused' || gameState === 'unveiled'">
        <h1 v-if="countdown > 0" style="color: white; position: absolute; font-size: 800%">{{countdown}}</h1>
        <img :src="coverUrl" style="width: 25vw; height: 25vw;" alt="" srcset="">
      </div>
      <!-- track name and artist -->
      <b-col v-if="countdown === 0" class="d-flex flex-column flex-grow-1 justify-content-center dynamicColor" style="text-align: center;">
        <h1 :style="{ color: textColor }">{{trackName}}</h1>
        <h2 :style="{ color: textColor }">{{trackArtists}}</h2>
      </b-col>
      <b-col v-if="countdown !== 0 && pausedPlayer" class="d-flex flex-column flex-grow-1 justify-content-center" style="text-align: center;">
        <h1 v-if="!pausedPlayer.name">Player {{pausedPlayer.index}}</h1>
        <h1 v-if="pausedPlayer.name">{{pausedPlayer.name}}</h1>
        <h2>make your guess</h2>
      </b-col>
    </div>

    <div class="d-flex flex-row" style="width: 100vw;" v-if="!loadingTracks && $store.state.game.iPadMode">
      <!-- cover & countdown -->
      <div
        class="d-flex flex-column flex-grow-1 align-items-center justify-content-center playerControls"
        v-for="(p, i) in $store.state.game.players" :key="p.id" :style="{ backgroundColor: p.color }" @click="handleKeypress({key: p.key})"
      >
        <p>
          Player {{i + 1}}
          <br>
          Key: {{p.key.toUpperCase()}}
        </p>
      </div>
    </div>

    <!-- old stuff -->
    <div v-if="false">
      <b-row v-for="p in playlist.tracks.items" :key="p.track.id" style="margin-bottom:1rem">
        <b-col>
          {{p.track.artists.map((e) => e.name).join(', ')}} - {{p.track.name}}
        </b-col>
        <b-col>
          <b-button @click="playSong(p)">play</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import FastAverageColor from 'fast-average-color';
import FullScreenButton from '@/components/FullScreenButton';

export default {
  name: 'Game',
  data() {
    return {
      countdown: -1,
      countdownTimer: null,
      playlist: {},
      tracks: [],
      playing: {},
      pausedPlayer: null,
      gameState: 'guessing',
      textColor: 'white',
      fallbackCoverColor: 'white',
      joystickEvent: null,
    };
  },
  components: {
    FullScreenButton,
  },
  created() {
    window.addEventListener('keypress', this.handleKeypress);
    this.$ws.$on("message", this.handleWebsocket);
    this.joystickEvent = window.joypad.on('button_press', this.handleGamepad);
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.handleKeypress);
    this.$ws.$off("message", this.handleWebsocket);
    this.joystickEvent?.unsubscribe();
  },
  mounted() {
    this.getPlaylist();
    this.getTracks(0);
  },
  methods: {
    getPlaylist() {
      this.axios.get(`https://api.spotify.com/v1/playlists/${this.$store.state.game.playlistId}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then((res) => this.playlist = res.data);
    },
    getTracks(offset) {
      this.axios.get(`https://api.spotify.com/v1/playlists/${this.$store.state.game.playlistId}/tracks?limit=50&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then((res) => {
        this.tracks = [...this.tracks, ...res.data.items]
        if (this.tracks.length < res.data.total) this.getTracks(res.data.offset + 50);
      });
    },
    playSong(p) {
      this.axios.put(`https://api.spotify.com/v1/me/player/play?device_id=${this.$store.state.game.deviceId}`, {
        uris: [ p.track.uri ],
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then((res) => this.playlist = res.data);
    },
    rand() {
      clearInterval(this.countdownTimer);
      this.countdown = 30;
      this.gameState = 'guessing';
      const randTrack = Math.floor(Math.random() * (this.tracks.length - 1));
      this.playing = [];
      this.pausedPlayer = null;
      const track = this.tracks[randTrack];
      let seek = 0;
      if (this.$store.state.game.gameType === 'random') {
        seek = Math.round(Math.random() * (track.track.duration_ms - 30000));
        console.log(seek);
      }
      this.axios.put(`https://api.spotify.com/v1/me/player/play?device_id=${this.$store.state.game.deviceId}`, {
        uris: [ track.track.uri ],
        position_ms: seek,
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then(() => {
        this.playing = track;
        this.countdownTimer = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown === 0) {
            clearInterval(this.countdownTimer);
          }
        }, 1000);
      });
    },
    pause(player) {
      clearInterval(this.countdownTimer);
      this.pausedPlayer = player;
      this.axios.put(`https://api.spotify.com/v1/me/player/pause`, {}, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then(() => this.gameState = 'paused');
    },
    unveil() {
      this.axios.put(`https://api.spotify.com/v1/me/player/play`, {}, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then(() => this.gameState = 'unveiled');
      this.countdown = 0;
    },
    handleKeypress(e) {
      if (e.key === 'Enter') return this.rand();
      const player = this.$store.state.game.players.find((p) => p.key === e.key);
      if (player) this.handlePlayerEvent(player);
    },
    handleWebsocket(msg) {
      console.log(msg);
      const content = msg.split(':');
      if (content[0] !== 'button-press') return;
      const player = this.$store.state.game.players.find((p) => p.key === content[1]);
      if (player) this.handlePlayerEvent(player);
    },
    handleGamepad(e) {
      const player = this.$store.state.game.players.find((p) => p.key === e.detail.buttonName);
      if (player) this.handlePlayerEvent(player);
    },
    handlePlayerEvent(player) {
      if (player.gamepad) {
        const gp = Object.values(window.joypad.instances).find((i) => i.id === player.gamepad);
        window.joypad.vibrate(gp, {
          startDelay: 0,
          duration: 100,
          weakMagnitude: 1,
          strongMagnitude: 0,
        });
      }
      if (this.gameState === 'guessing' && this.countdown > 0) this.pause(player);
      if (this.gameState === 'guessing' && this.countdown <= 0) this.rand();
      if (player !== this.pausedPlayer) return;
      if (this.gameState === 'paused') this.unveil();
      if (this.gameState === 'unveiled') this.rand();
    }
  },
  computed: {
    coverUrl() {
      const unknownCover = 'https://t3.ftcdn.net/jpg/04/23/89/98/360_F_423899840_2x7wg6gjpXej4iXHruiWJcLJAzJgSD9L.jpg';
      if (this.countdown !== 0) return unknownCover;
      return this.playing?.track?.album?.images[0].url 
    },
    hiddenCoverUrl() {
      return this.playing?.track?.album?.images[0].url 
    },
    trackName() {
      return this.playing?.track?.name || '???'
    },
    trackArtists() {
      return this.playing?.track?.artists?.map((e) => e.name).join(', ') || '???';
    },
    loadingTracks() {
      if (this.tracks.length === 0 || !this.playlist || !this.playlist.tracks) return true;
      return this.tracks.length < this.playlist.tracks.total;
    },
    backgroundColor() {
      if (!this.pausedPlayer) return 'white';
      else return this.pausedPlayer.color;
    }
  },
  watch: {
    hiddenCoverUrl() {
      const fac = new FastAverageColor();
      fac.getColorAsync(document.querySelector('#cover'))
        .then(color => {
          this.textColor = color.isDark ? 'white' : 'black';
          this.fallbackCoverColor = color.hex;
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
}
</script>

<style scoped>
.playerControls {
  height: 10vh; 
  text-align: 'center';
  cursor: pointer;
}

.blurCover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.blurImage {
  width: 150vw;
  height: 150vw;
  position: fixed;
  top: -50vw;
  left: -25vw;
  backdrop-filter: blur(100vw);
  filter: blur(100vw);
}
</style>